body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input[type="text"] {
  padding: 8px;
  width: 80%;
  border: 1px solid rgb(192, 192, 192);
}

details {
  padding: 5px;
  border: 1px solid lightgrey;
}

fieldset {
  border: 1px solid rgb(192, 192, 192);
  padding: 10px;
}

legend {
  font-weight: bold;
  font-size: 18px;
}

p {
  margin: 4px 0;
}

.invoice-details-table {
  font-size: 12px;
}

.invoice-details-table tr {
  margin-bottom: 5px;
}

svg.spinner {
  animation: rotate 2s linear infinite;
}

svg.spinner circle {
  stroke: #333;
  stroke-linecap: round;
  stroke-width: 5;
  animation: dash 1.5s ease-in-out infinite;
}

.invoice-row {
  display: flex;
  position: relative;
  justify-content: space-between;
  background: rgb(255, 255, 255);
  border-radius: 0.25rem;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 2px, rgba(0, 0, 0, 0.14) 0px 2px 4px;
  padding: 0.5rem;
  margin: 0px 0px 0.8rem;
  list-style: none;
  z-index: inherit;
  transition: background 200ms cubic-bezier(0.2, 0, 0, 1) 0s;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
